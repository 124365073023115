import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import UserIcon from "../../images/UserIcon";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Logo from "../../assets/images/logo.png";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ListItemButton from "@mui/material/ListItemButton";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import QrCodeIcon from '@mui/icons-material/QrCode';
import PaymentIcon from '@mui/icons-material/Payment';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingIcon from "images/settingIcon";

function LeftContent() {
  const navigate = useNavigate();
  const [, updateState] = React.useState({});
  const forceUpdate = React.useCallback(() => updateState({}), []);

  const [menusList, setMenuList] = useState([
    // Admin Users menu
    {
      icon: <DashboardIcon />,
      label: "Dashboard",
      src: "dashboard",
      value: "dashboard",
      hasMoreMenu: false,
    },
    {
      icon: <UserIcon />,
      label: "Total User",
      src: "Host",
      value: "host",
      hasMoreMenu: false,
    },
    // Users menu with expandable submenus
    {
      icon: <UserIcon />,
      label: "Total Player",
      src: "Users",
      value: "user",
      hasMoreMenu: false,
    },
    // Game One History List
    {
      icon: <SportsEsportsIcon />,
      label: "Game Record",
      src: "one-history",
      hasMoreMenu: false,
      value: "helpAndSupport",
      routeKey: "one-history",
    },
    // Game Two History List
    // {
    //   icon: <SportsEsportsIcon />,
    //   label: "Two History",
    //   src: "two-history",
    //   hasMoreMenu: false,
    //   value: "helpAndSupport",
    //   routeKey: "two-history",
    // },
    // // Game Three History List
    // {
    //   icon: <SportsEsportsIcon />,
    //   label: "Three History",
    //   src: "three-history",
    //   hasMoreMenu: false,
    //   value: "helpAndSupport",
    //   routeKey: "two-history",
    // },
    // // Game Four History List
    // {
    //   icon: <SportsEsportsIcon />,
    //   label: "Four History",
    //   src: "four-history",
    //   hasMoreMenu: false,
    //   value: "helpAndSupport",
    //   routeKey: "two-history",
    // },
    // {
    //   icon: <PaymentIcon />,
    //   label: "Payment History",
    //   hasMoreMenu: true,
    //   isExpanded: false,
    //   id: "userId",
    //   value: "payment-history",
    //   expandArray: [
    //     {
    //       icon: "",
    //       label: "Deposit History",
    //       src: "deposit-history",
    //     },
    //     {
    //       icon: "",
    //       label: "Withdraw History",
    //       src: "withdraw-history",
    //     },
    //   ],
    // },
    // {
    //   icon: <QrCodeIcon />,
    //   label: "Image Barcode",
    //   src: "image-management",
    //   hasMoreMenu: false,
    //   value: "imagemanagement",
    //   routeKey: "image-management",
    // },
    {
      icon: <QrCodeIcon />,
      label: "Customer Care",
      src: "customer-care",
      hasMoreMenu: false,
      value: "imagemanagement",
      routeKey: "customer-care",
    },
    // {
    //   icon: <QrCodeIcon />,
    //   label: "Build Upload",
    //   src: "file-upload",
    //   hasMoreMenu: false,
    //   value: "imagemanagement",
    //   routeKey: "file-upload",
    // },
    {
      icon: <QrCodeIcon />,
      label: "Live Bet Counter",
      src: "live-bet-counter",
      hasMoreMenu: false,
      value: "imagemanagement",
      routeKey: "live-bet-counter",
    },
    {
      icon: <SettingIcon />,
      label: "Setting",
      src: "setting",
      hasMoreMenu: false,
      value: "imagemanagement",
      routeKey: "setting",
    },
  ]);

  const handleClicked = (e) => {
    navigate(`/${e}`);
  };

  const ToggleMenu = (Id) => {
    menusList.filter((item) => item.id === Id)[0].isExpanded =
      !menusList.filter((item) => item.id === Id)[0].isExpanded;
    forceUpdate();
  };

  return (
    <div className="gp_left-bar">
      <div className="side_logo_box">
        <Link className="side_logo" to="/dashboard">
          <img src={Logo} alt={"Logo"} className={"Logo"} />
        </Link>
      </div>
      <List
        sx={{ width: "100%", maxWidth: 360, padding: "0" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={"tab_sidebar_details"}
      >
        {menusList?.map((menu, i) => (
          <React.Fragment key={i}>
            <ListItemButton
              sx={{ padding: "5px 15px" }}
              selected={
                menu?.src === window.location.pathname.replace("/", "")
              }
              className="list_item fontFamily"
              onClick={() =>
                menu.hasMoreMenu
                  ? ToggleMenu(menu?.id)
                  : handleClicked(menu?.src)
              }
            >
              <ListItemIcon
                style={{ minWidth: "20px" }}
                className={"icon-left-side fontFamily"}
              >
                {menu?.icon}
              </ListItemIcon>
              <ListItemText
                primary={menu?.label}
                className="menu_label fontFamily"
              />
              {menu?.hasMoreMenu ? (
                menu?.isExpanded ? (
                  <ExpandLess />
                ) : (
                  <ExpandMore />
                )
              ) : (
                ""
              )}
            </ListItemButton>
            {menu?.hasMoreMenu && menu?.isExpanded && (
              <List component="div" disablePadding>
                {menu.expandArray.map((subMenu, index) => (
                  <ListItemButton
                    key={index}
                    sx={{ pl: 4 }}
                    onClick={() => handleClicked(subMenu.src)}
                  >
                    <ListItemText style={{ paddingLeft: "2rem" }} primary={subMenu.label} />
                  </ListItemButton>
                ))}
              </List>
            )}
          </React.Fragment>
        ))}
      </List>
    </div>
  );
}

export default LeftContent;
