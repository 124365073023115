const initialState = {
  userList: {
    limit: 5,
    page: 0,
    list: [],
    totalDocs: 0,
  },
  userProfile: {},
  gameStatistics: [],
  userOverView: {},
  noteList: {
    limit: 5,
    page: 0,
    list: [],
    totalDocs: 0,
  },
};

export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case "USER_DATA_DETAILS": {
      return {
        ...state,
        userList: {
          limit: payload.limit,
          page: payload.page,
          totalDocs: payload?.totalDocs,
          list: payload.docs,
        },
      };
    }
    case "USER_BLOCK_DETAILS": {
      let temp = [...state.userList.list];
      let index = temp.findIndex((item) => item.id === payload.id);
      if (index > -1) {
        temp[index].isBlock = payload.isBlock;
      }
      return {
        ...state,
        userList: {
          ...state.userList,
          list: temp,
        },
      };
    }
    case "USER_PROFILE_DETAILS": {
      return {
        ...state,
        userProfile: payload,
      };
    }
    case "USER_UPDATE_PROFILE": {
      return {
        ...state,
        userProfile: payload,
      };
    }
    default:
      return state;
  }
}
