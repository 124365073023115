import {combineReducers} from "redux";
import userReducer from "../user/reducer";
import errorReducer from "../errorReducer/reducer";
import {SDKManagementReducer} from "../SDKManagement/reducer";
import {authReducer} from "../auth/reducer";
import analyticsReducer from "../AnalyticsReport/reducer";

export default combineReducers({
    authReducer,
    userReducer,
    errorReducer,
    SDKManagementReducer,
    analyticsReducer,
})