import React from "react";

const Dashboard = React.lazy(() => import("Pages/Dashboard"));
//auth
const Login = React.lazy(() => import("Pages/Authentication/Login"));
const ResetPassword = React.lazy(() => import("../Pages/Authentication/ResetPassword"));
const ChangePassword = React.lazy(() => import("Pages/Authentication/ChangePassword"));

const UserData = React.lazy(() => import("../Pages/Users/AllUserList"))
const UsersTab = React.lazy(() => import("../Pages/Users/UserTab"));

const HostData = React.lazy(() => import("../Pages/Host/AllUserList"))
const HostTab = React.lazy(() => import("../Pages/Host/UserTab"));

//Game One History List
const OneHistory = React.lazy(() => import("../Pages/OneHistory"))
const Setting = React.lazy(() => import("../Pages/setting"))

//Game Two History List
const TwoHistory = React.lazy(() => import("../Pages/TwoHistory"))

//Game Three History List
const ThreeHistory = React.lazy(() => import("../Pages/ThreeHistory"))

//Game Four History List
const FourHistory = React.lazy(() => import("../Pages/FourHistory"))

//Game Payment History List
const DepositHistory = React.lazy(() => import("../Pages/PaymentHistory/DepositHistory"))
const WithdrawHistory = React.lazy(() => import("../Pages/PaymentHistory/WithdrawHistory"))

//Bank Details List
const ImageBarcode = React.lazy(() => import("../Pages/ImageBarcode"))

//Customer Care
const CustomerCare = React.lazy(() => import("../Pages/CustomerCareTab"))

//FileUpload
const FileUpload = React.lazy(() => import("../Pages/FileUpload"))

//Live Bet Counter
const LiveBetCounter = React.lazy(() => import("../Pages/LiveBetCounter"))

export const PublicroutesArray = [
    { path: "/", exact: true, component: Login },
    { path: "/reset/:id/:id", component: ResetPassword, title: "Reset Password" },
];

export const PrivateroutesArray = [
    { path: "/dashboard", component: Dashboard, title: "Dashboard" },
    { path: "/change-password", component: ChangePassword, title: "Change Password" },

    //User
    {
        path: "Users", component: UserData, title: "Total Player"
    },
    //Host
    {
        path: "Host", component: HostData, title: "Total User"
    },
    { path: "users-tab/:id", component: UsersTab, title: "Users Details" },
    { path: "host-tab/:id", component: HostTab, title: "Users Details" },

    //Game One History List
    { path: "/one-history", component: OneHistory, title: "Game Record" },
    { path: "/setting", component: Setting, title: "setting" },
    //Game Two History List
    { path: "/two-history", component: TwoHistory, title: "Two History List" },

    //Game One History List
    { path: "/three-history", component: ThreeHistory, title: "Three History List" },

    //Game One History List
    { path: "/four-history", component: FourHistory, title: "Four History List" },

    //Payment History List
    { path: "/deposit-history", component: DepositHistory, title: "Deposit History" },
    { path: "/withdraw-history", component: WithdrawHistory, title: "Withdraw History" },

    //IMAGE MANAGEMENT
    { path: "/image-management", component: ImageBarcode, title: "IMAGE MANAGEMENT" },

    //Customer Care
    { path: "/customer-care", component: CustomerCare, title: "Customer Care" },

    //file-upload
    { path: "/file-upload", component: FileUpload, title: "Build Upload" },

    //Live Bet Counter
    { path: "/live-bet-counter", component: LiveBetCounter, title: "Live Bet Counter" },
];

//GameCategory